import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { md, colors } from '../common'

export default () => {
  return(
  <Wrap>
    <Copy>
      <h1>APPRAISALS</h1>
      <p>There are many different reasons to have your jewelry appraised, the most common being insurance. You may also need an appraisal for estate purposes, a resale value or a court ordered distribution of assets. Underwood’s contracts a part-time independent Graduate Gemologist who works in all of our locations several days a month. The cost for an appraisal is $150/hour, with a one hour minimum. Please call your preferred location to check the appraiser’s schedule.</p>
      <p>Are you only interested in the cash value of your jewelry? If so, an appraisal may not be the economical choice.</p>
      <br />
      <Btn to={'/trading-selling'}>Trade or sell your jewelry</Btn>
      <br /><br /><br />
      <Btn to={'/locations'}>Contact the location nearest you</Btn>
    </Copy>
  </Wrap>
)}

const Wrap = styled.div`
  background: url("https://s3.amazonaws.com/underwoods/jewelry+repair/appraisal-bg.jpg");
  background-size: cover;
  background-position: center top;
  padding: 4rem;
  color: white;
  text-align: center;

  ${md} {
    padding: 1rem;
  }
`

const Copy = styled.div`
  width:40%;
  margin-left:auto;
  padding: 1rem;
  line-height:24px;

  h1 {
    line-height:32px;
  }

  ${md} {
    margin: 0;
    width: 90%;
  }
`

const Btn = styled(Link)`
  background-color:white;
  color:${colors.green};
  padding:.75rem;
  margin:1rem;
  text-transform: uppercase;
`
