import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import JoinUs from '../sections/joinUs'
import GreenBar from '../sections/watch-greenBar4'
import Appraisals from '../sections/jr-apprasials'
import { PageHead } from '../common'
import { CardWrap, Card } from './watch-repair'

export default () => (
  <Layout>
    <SEO title="Repair & Appraisals" />
    <PageHead>
      <h1>JEWELRY REPAIR & APPRAISALS</h1>
      <p>At Underwood’s we have made investments in our repair shops of the finest laser technology equipment available in the world. This laser technology allows us to fuse metal together, creating a stronger and more pure finish to jewelry. This permits us to size and repair in a variety of metals without the danger of heat damage associated with traditional acetylene torch repair methods. This technology is particularly beneficial in the sizing and repair of platinum jewelry and silver jewelry.</p>
      <p>Underwood’s has been in business since 1928 which has earned us our reputation of quality, integrity and care. Our jewelers have almost 80 years combined experience in jewelry repair and receive continuing education across the country on an annual basis. They are master craftsmen who will take care of your jewelry as if it were their own.</p>
    </PageHead>
    <GreenBar />
    <CardWrap>
      <Card>
        <img src="https://s3.amazonaws.com/underwoods/jewelry+repair/01ringsizing.jpg" alt="diamond ring being put on" />
        <h3>RING SIZING</h3>
        <p>We can size most sterling, gold and platinum rings. Laser technology allows us to size rings without the danger of leaving sizing marks behind. Sizing starts at $35 and goes up depending on the type and amount of metal/work needed. The jeweler must see your ring for an exact estimate.</p>
      </Card>
      <Card>
        <img src="https://s3.amazonaws.com/underwoods/jewelry+repair/02prong.jpg" alt="rings on workbench with pliars" />
        <h3>PRONG WORK</h3>
        <p>It’s a good idea to bring your jewelry in every 6 months or so to have us inspect the prongs which we are happy to do for FREE. Our jewelers can re-tip or re-build prongs to keep your diamonds and gemstones safe.</p>
      </Card>
      <Card>
        <img src="https://s3.amazonaws.com/underwoods/jewelry+repair/03clasprepair.jpg" alt="braclet being closely inspected" />
        <h3>CHAIN & CLASP REPAIR</h3>
        <p>Bring your broken chains to any of our stores to be repaired. Soldering prices start at $25. We must see your jewelry to give you an exact estimate.</p>
      </Card>
      <Card>
        <img src="https://s3.amazonaws.com/underwoods/jewelry+repair/04diamondreplacement.jpg" alt="diamond being placed in setting" />
        <h3>DIAMOND & GEMSTONE REPLACEMENT</h3>
        <p>It’s a terrible feeling when you lose a diamond or gemstone. We can match your missing stones as closely as possible and have your jewelry looking brand new in no time!</p>
      </Card>
      <Card>
        <img src="https://s3.amazonaws.com/underwoods/jewelry+repair/05jewelrycleaning.jpg" alt="ring being polished" />
        <h3>JEWELRY CLEANING & INSPECTION</h3>
        <p>Our professional staff can inspect and clean your jewelry, usually for no charge and while you wait*. It’s a good idea to have your rings inspected every 6 months and you may as well sparkle them up while you’re here.
        <br /><br /><span css={`font-size:12px;`}>*Subject to staff availability and quantity of jewelry. If you bring in your entire jewelry box, we will happily give you a receipt and an expected time frame for you to return to pick up your newly cleaned jewelry!</span></p>
      </Card>
      <Card>
        <img src="https://s3.amazonaws.com/underwoods/jewelry+repair/06muchmore2.jpg" alt="diamond ring viewed through loup" />
        <h3>MUCH MORE</h3>
        <p>Including: Ring soldering, ring cleats, Fingermate for arthritic hands, earring posts, earring backs, bracelet repair, chain shortening, rhodium plating, pearl restringing and more.</p>
      </Card>
    </CardWrap>
    <Appraisals />
    <JoinUs />
  </Layout>
)
